export enum AsyncActionTypes {
  AccountExists = 'ACCOUNT_EXISTS',
  SendSmsCode = 'SEND_SMS_CODE',
  AuthByOauth = 'AUTH_BY_OAUTH',
  PasswordLogin = 'PASSWORD_LOGIN',
  TokenLogin = 'TOKEN_LOGIN',
  RecoverPassword = 'RECOVER_PASSWORD',
  ResendSmsCode = 'RESEND_SMS_CODE',
  SendVoiceCode = 'SEND_VOICE_CODE',
  Logout = 'LOGOUT',
  Signup = 'SIGNUP',
  PreSignup = 'PRE_SIGNUP',
  DeleteAccount = 'DELETE_ACCOUNT',
  CheckAccountInfo = 'CHECK_ACCOUNT_INFO',
  ResendConfirmationEmail = 'RESEND_CONFIRMATION_EMAIL',
  UploadImage = 'UPLOAD_IMAGE',
  UploadChatImage = 'UPLOAD_CHAT_IMAGE',
  GetPersonalBotChat = 'GET_PERSONAL_BOT_CHAT',
  WsHistory = 'WS_HISTORY',
  WsGetChatSuggestionList = 'WS_GET_CHAT_SUGGESTION_LIST',
  WsStartChatSuggestion = 'WS_START_CHAT_SUGGESTION',
  WsSetMessageReaction = 'WS_SET_MESSAGE_REACTION',
  GetPersonalBot = 'GET_PERSONAL_BOT',
  GetCoreDescription = 'GET_CORE_DESCRIPTION',
  UpdateBackstory = 'UPDATE_BACKSTORY',
  UpdateActLike = 'UPDATE_ACT_LIKE',
  GetUserProfile = 'GET_USER_PROFILE',
  GetMediaPreview = 'GET_MEDIA_PREVIEW',
  WsSendMessage = 'WS_SEND_MESSAGE',
  WsTextInputDetected = 'WS_TEXT_INPUT_DETECTED',
  WsAppForeground = 'WS_APP_FOREGROUND',
  WsSendFeedback = 'WS_SEND_FEEDBACK',
  WsDismissFeedback = 'WS_DISMISS_FEEDBACK',
  GetImage = 'GET_IMAGE',
  WsInit = 'WS_INIT',
  UpdateUserProfile = 'UPDATE_USER_PROFILE',
  UpdateUserPassword = 'UPDATE_USER_PASSWORD',
  AddPassword = 'ADD_PASSWORD',
  UpdateUserEmail = 'UPDATE_USER_EMAIL',
  SetPasswordAndEmail = 'SET_PASSWORD_AND_EMAIL',
  PasswordVerification = 'PASSWORD_VERIFICATION',
  GetVoices = 'GET_VOICES',
  UpdateBot = 'UPDATE_BOT',
  SubmitReboarding = 'SUBMIT_ONBOARDING',
  GetImageSignedUrl = 'GET_IMAGE_SIGNED_URL',
  InitNewUser = 'INIT_NEW_USER',
  GetTracks = 'GET_TRACKS',
  GetMissions = 'GET_MISSIONS',
  WsStartMission = 'WS_START_MISSION',
  WsSkipMission = 'WS_SKIP_MISSION',
  WsDropMission = 'WS_DROP_MISSION',
  GetSpotlight = 'GET_SPOTLIGHT',
  GetTrack = 'GET_TRACK',
  GetSubscriptions = 'GET_SUBSCRIPTIONS',
  WsMainScreen = 'WS_MAIN_SCREEN',
  WsChatScreen = 'WS_CHAT_SCREEN',
  WsApplicationStarted = 'WS_APPLICATION_STARTED',
  GetAvatars = 'GET_AVATARS',
  GetMemory = 'GET_MEMORY',
  GetNewMemory = 'GET_NEW_MEMORY',
  CreateMemoryPerson = 'CREATE_MEMORY_PERSON',
  UpdateMemoryPerson = 'UPDATE_MEMORY_PERSON',
  CreateMemoryFact = 'CREATE_MEMORY_FACT',
  UpdateMemoryFact = 'UPDATE_MEMORY_FACT',
  ProcessNewMemories = 'PROCESS_NEW_MEMORIES',
  MarkMemoryRead = 'MARK_MEMORY_READ',
  DeleteMemory = 'DELETE_MEMORY',
  BatchDeleteMemories = 'BATCH_DELETE_MEMORIES',
  GetMemoryRelations = 'GET_MEMORY_RELATIONS',
  GetMemoryCategories = 'GET_MEMORY_CATEGORIES',
  GetStripeConfig = 'GET_STRIPE_CONFIG',
  GetPayPalConfig = 'GET_PAYPAL_CONFIG',
  CreateStripeCustomer = 'CREATE_STRIPE_CUSTOMER',
  CreateStripeSubscription = 'CREATE_STRIPE_SUBSCRIPTION',
  RetryStripeInvoice = 'RETRY_STRIPE_INVOICE',
  GetStripePrices = 'GET_STRIPE_PRICES',
  DeleteStripeSubscription = 'DELETE_STRIPE_SUBSCRIPTION',
  WsWalletRequest = 'WS_WALLET_REQUEST',
  GetGemConversions = 'GET_GEM_CONVERSIONS',
  GetGemPurchases = 'GET_GEM_PURCHASES',
  ConvertGems = 'CONVERT_GEMS',
  PurchaseGems = 'PURCHASE_GEMS',
  ClaimDailyReward = 'CLAIM_DAILY_REWARD',
  GetCategoryTreeNode = 'GET_CATEGORY_TREE_NODE',
  GetStoreItems = 'GET_STORE_ITEMS',
  GetStoreItemsByIds = 'GET_STORE_ITEMS_BY_IDS',
  GetStoreUserItems = 'GET_STORE_USER_ITEMS',
  BuyUserItems = 'BUY_USER_ITEMS',
  SwitchUserItem = 'SWITCH_USER_ITEM',
  GetLoginReward = 'GET_LOGIN_REWARD',
  GetAvatarMeta = 'GET_AVATAR_META',
  GetUnityBinaries = 'GET_UNITY_BINARIES',
  GetStorefront = 'GET_STOREFRONT',
  GetUnityWebEngine = 'GET_UNITY_WEB_ENGINE',
  WsChangeVoiceMode = 'WS_CHANGE_VOICE_MODE',
  WsStartVoiceStreaming = 'WS_START_VOICE_STREAMING',
  WsStreamVoiceChunks = 'WS_STREAM_VOICE_CHUNKS',
  WsEndVoiceStreaming = 'WS_END_VOICE_STREAMING',
  WsSendVoiceCallFeedback = 'WS_SEND_VOICE_CALL_FEEDBACK',
  GetStripeCoupons = 'GET_STRIPE_COUPONS',
  GetRelatioshipStatuses = 'GET_RELATIONSHIP_STATUSES',
  GetMedia = 'GET_MEDIA',
  SendPayPalPurchaseData = 'SEND_PAYPAL_PURCHASE_DATA',
  GetUserInterests = 'GET_USER_INTERESTS',
  UpdateUserInterests = 'UPDATE_USER_INTERESTS',
  GetVoiceMessage = 'GET_VOICE_MESSAGE',
  UploadVoiceMessage = 'UPLOAD_VOICE_MESSAGE',
  GetDialogModels = 'GET_DIALOG_MODELS',
  SetDialogModel = 'SET_DIALOG_MODEL',
  GetAdvancedAiInfo = 'GET_ADVANCED_AI_INFO',
  BuyAdvancedAiMessages = 'BUY_ADVANCED_AI_MESSAGES',
}

export enum ActionTypes {
  ChatInitialized = 'CHAT_INITIALIZED',
  InitPersist = 'INIT_PERSIST',
  InitMetrics = 'INIT_METRICS',
  UpdatePersist = 'UPDATE_PERSIST',
  ResetPersist = 'RESET_PERSIST',
  CheckDeviceId = 'CHECK_DEVICE_ID',
  LoginFieldChange = 'LOGIN_FIELD_CHANGE',
  SetCodeResendTimeout = 'SET_CODE_RESEND_TIMEOUT',
  WsStart = 'WS_START',
  WsOpen = 'WS_OPEN',
  WsClose = 'WS_CLOSE',
  WsDisconnect = 'WS_DISCONNECT',
  WsMessageReceived = 'WS_MESSAGE_RECEIVED',
  WsError = 'WS_ERROR',
  WsChatMessageReceived = 'WS_CHAT_MESSAGE_RECEIVED',
  WsVoiceRecordReceived = 'WS_VOICE_RECORD_RECEIVED',
  WsMessageReactionReceived = 'WS_MESSAGE_REACTION_RECEIVED',
  WsMessageEmotionReceived = 'WS_MESSAGE_EMOTION_RECEIVED',
  WsFeedbackRequestReceived = 'WS_FEEDBACK_REQUEST_RECEIVED',
  WsStartTypingReceived = 'WS_START_TYPING_RECEIVED',
  WsStopTypingReceived = 'WS_STOP_TYPING_RECEIVED',
  WsVoiceModeReceived = 'WS_VOICE_MODE_RECEIVED',
  WsBotMoodReceived = 'WS_BOT_MOOD',
  CloseFeedbackPopup = 'CLOSE_FEEDBACK_POPUP',
  BotStatsReceived = 'BOT_STATS_UPDATE_RECEIVED',
  BotChatReceived = 'BOT_CHAT_UPDATE_RECEIVED',
  BotReceived = 'BOT_UPDATE_RECEIVED',
  SetDefaultChat = 'SET_DEFAULT_CHAT',
  GoneOffline = 'GONE_OFFLINE',
  GoneOnline = 'GONE_ONLINE',
  SetActiveWindow = 'SET_ACTIVE_WINDOW',
  SetTheme = 'SET_THEME',
  TrialPeriodExpired = 'TRIAL_PERIOD_EXPIRED',
  ResetServerError = 'RESET_SERVER_ERROR',
  AcceptGdpr = 'ACCEPT_GDPR',
  GoToReboardingStep = 'GO_TO_REBOARDING_STEP',
  UpdateReboardingState = 'UPDATE_REBOARDING_STATE',
  GoToSignupStep = 'GO_TO_SIGNUP_STEP',
  UpdateSignupState = 'UPDATE_SIGNUP_STATE',
  SetHintStatus = 'SET_HINT_STATUS',
  UpdateChatMessageText = 'UPDATE_CHAT_MESSAGE_TEXT',
  SkipWidget = 'SKIP_WIDGET',
  InitNewUser = 'INIT_NEW_USER',
  UploadChatImagePreview = 'UPLOAD_CHAT_IMAGE_PREVIEW',
  SetActiveDialog = 'SET_ACTIVE_DIALOG',
  QueueDialog = 'QUEUE_DIALOG',
  CloseDialog = 'CLOSE_DIALOG',
  WsJourneyChanged = 'WS_JOURNEY_CHANGED',
  SetReboardingDialogShown = 'SET_REBOARDING_DIALOG_SHOWN',
  SetShowRelationshipStatusButton = 'SET_SHOW_RELATIONSHIP_STATUS_BUTTON',
  DismissNotification = 'DISMISS_NOTIFICATION',
  SetChooseAvatarScreenShown = 'SET_CHOOSE_AVATAR_SCREEN_SHOWN',
  SetShowMessageActions = 'SET_SHOW_MESSAGE_ACTIONS',
  HideMessageActions = 'HIDE_MESSAGE_ACTIONS',
  WsStatementRemembered = 'WS_STATEMENT_REMEMBERED',
  ClearChatMessageAlert = 'CLEAR_CHAT_MESSAGE_ALERT',
  WsNavigationPopup = 'WS_NAVIGATION_POPUP',
  SetStripeLatestInvoiceId = 'SET_STRIPE_LATEST_INVOICE_ID',
  SetStripeEnabled = 'SET_STRIPE_ENABLED',
  SetSubscriptionEventData = 'SET_SUBSCRIPTION_EVENT_DATA',
  SetRedirectTo = 'SET_REDIRECT_TO',
  WsWalletReceived = 'WS_WALLET_RECEIVED',
  WsNotificationsReceived = 'WS_NOTIFICATIONS_RECEIVED',
  QueueSystemNotification = 'QUEUE_SYSTEM_NOTIFICATION',
  DismissSystemNotification = 'DISMISS_SYSTEM_NOTIFICATION',
  QueueNotification = 'QUEUE_NOTIFICATION',
  SetAvatarMode = 'SET_AVATAR_MODE',
  SetAvatarQuality = 'SET_AVATAR_QUALITY',
  SetAvatarStatus = 'SET_AVATAR_STATUS',
  SetLastUnityVersion = 'SET_LAST_UNITY_VERSION',
  ResetAvatarCustomization = 'RESET_AVATAR_CUSTOMIZATION',
  UpdateVoiceCallAudioQueue = 'UPDATE_VOICE_CALL_AUDIO_QUEUE',
  SaveVoiceCallStartTime = 'SAVE_VOICE_CALL_START_TIME',
  SaveVoiceMessageTime = 'SAVE_VOICE_MESSAGE_TIME',
  ClearVoiceMessageTime = 'CLEAR_VOICE_MESSAGE_TIME',
  SuggestionListReceived = 'SUGGESTION_LIST_RECEIVED',
  WsSaleScreenReceived = 'WS_SALE_SCREEN_RECEIVED',
  SetRelationshipStatusToUpdate = 'SET_RELATIONSHIP_STATUS_TO_UPDATE',
  SetRoomSettings = 'SET_ROOM_SETTINGS',
  SetAvatarViewMode = 'SET_AVATAR_VIEW_MODE',
  CompleteSignup = 'COMPLETE_SIGNUP',
  SetOnboardingStatus = 'SET_ONBOARDING_STATUS',
  OauthSignInRequest = 'OATH_SIGN_IN_REQUEST',
  OauthSignInSuccess = 'OATH_SIGN_IN_SUCCESS',
  OauthSignInError = 'OATH_SIGN_IN_ERROR',

  // required for 3rg party libs
  OtherAction = '__any_other_action_type__',

  UpdateGlobalVoiceMessageState = 'UPDATE_GLOBAL_VOICE_MESSAGE_STATE',
  UpdateLastPlayedVoiceMessage = 'UPDATE_LAST_PLAYED_VOICE_MESSAGE',

  WsProfileReceived = 'WS_PROFILE_RECEIVED',
  WsAdvancedAiMessagesReceived = 'WS_ADVANCED_AI_MESSAGES_RECEIVED',
  ToggleAdvancedAi = 'TOGGLE_ADVANCED_AI',
  ToogleAdvancedAiBoostWidget = 'TOOGLE_ADVANCED_AI_BOOST_WIDGET',
  SetMessageRememberedWidgetShown = 'SET_MESSAGE_REMEMBERED_WIDGET_SHOWN',

  PromptsSetPanelSelectedCategory = 'PROMPTS_SET_PANEL_SELECTED_CATEGORY',
  PromptsClearPanelSelectedCategory = `PROMPTS_CLEAR_PANEL_SELECTED_CATEGORY`,
  PromptsStartPrompt = `PROMPTS_START_PROMPT`,
  PromptsStopPrompt = `PROMPTS_STOP_PROMPT`,
  PromptsShowChatWidget = `PROMPTS_SHOW_CHAT_WIDGET`,
  PromptsHideChatWidget = `PROMPTS_HIDE_CHAT_WIDGET`,
  SaleScreenShown = 'SALE_SCREEN_SHOWN',
  SetFreshSignup = 'SET_FRESH_SIGNUP',
  SetActiveSidePanel = 'SET_ACTIVE_SIDE_PANEL',
  WsMemoryNewFactsUpdateReceived = 'WS_MEMORY_NEW_FACTS_UPDATE_RECEIVED',
}

export enum AuthTypes {
  AuthByPassword = 'auth_by_password',
  SendSmsCode = 'send_sms_code',
}

export enum WsEventNames {
  Error = 'error',
  Init = 'init',
  NewInit = 'new_init',
  History = 'history',
  Message = 'message',
  MessageEmotion = 'message_emotion',
  MessageReaction = 'message_reaction',
  Bot = 'bot',
  PersonalBotStats = 'personal_bot_stats',
  PersonalBotChat = 'personal_bot_chat',
  ConversationFeedback = 'conversation_feedback',
  StartTyping = 'start_typing',
  StopTyping = 'stop_typing',
  TextInputDetected = 'text_input_detected',
  VoiceMode = 'voice_mode',
  AppForeground = 'app_foreground',
  StartMission = 'start_mission',
  SkipMission = 'skip_mission',
  DropMission = 'drop_mission',
  JourneyChanged = 'journey_changed',
  MainScreen = 'main_screen',
  ChatScreen = 'chat_screen',
  ApplicationStarted = 'application_started',
  StatementRemembered = 'statement_remembered',
  NavigationPopup = 'navigation_popup',
  Wallet = 'wallet',
  WalletRequest = 'wallet_request',
  Notifications = 'notifications',
  ChangeVoiceMode = 'voice_mode',
  StartVoiceStreaming = 'voice_streaming_started',
  StreamVoiceChunks = 'voice_stream_chunk',
  EndVoiceStreaming = 'voice_streaming_ended',
  ChatSuggestionList = 'chat_suggestions_list',
  StartChatSuggestion = 'start_chat_suggestion',
  SendVoiceCallFeedback = 'voicecall_feedback',
  UserProfile = 'user_profile',
  AdvancedAiMessages = 'advanced_ai_messages',
  MessageReroll = 'reroll',
  SubscriptionRequest = 'subscription_request',
  DiaryUnreadUpdate = 'diary_unread_update',
  BotMoodUpdate = 'bot_mood_v2',
  MemoryNewFactsUpdate = 'new_facts_update',
  VoiceSampleStream = 'voice_sample_stream',
  AIGeneratedImageSuccess = 'ai_generated_image_success',
  AIGeneratedImagePreview = 'ai_generated_image_preview',
  AIGeneratedImageFailure = 'ai_generated_image_failure',
}

export enum SubscriptionRequestTypes {
  Sale = 'sale',
  SubscriptionScreen = 'subscription_screen',
}

export enum MetricsEvents {
  Launched = 'Application launched',
  Upvote = 'Upvote',
  Downvote = 'Downvote',
  AccountDeleted = 'Account deleted',
  NeedHelpHotlineCalled = 'Need help - Hotline called',
  NeedHelpLifelineChatClicked = 'Need help - Lifeline chat clicked',
  NeedHelpWebsiteClicked = 'Need help - Website clicked',
  ForgotPassword = 'Forgot password',
  WelcomeScreenOpened = 'Welcome screen opened',
  SignupScreenOpened = 'Signup screen opened', // RENAME: Sign-up screen opened
  LoginScreenOpened = 'Login screen opened', // RENAME: Sign-in screen opened
  SignupError = 'Sign-up error',
  SigninError = 'Sign-in error',
  OnboardingCreatePassword = 'Onboarding create password screen opened',
  OnboardingDownload = 'Onboarding download screen opened',
  OnboardingDownloadScreenButtonTapped = 'Onboarding download screen button tapped',
  OnboardingAvatarScreen = 'Onboarding avatar screen opened',
  OnboardingReplikaNameScreen = 'Replika name screen opened',
  UserNameScreenOpened = 'User name screen opened',
  UserPronounsScreenOpened = 'User pronouns screen opened',
  DialogVersionScreenOpened = 'Dialog version screen opened',

  UserDOBScreenOpened = 'User DOB screen opened',
  AgeRangeScreenOpened = 'Age range screen opened',
  YoungsterError = 'Youngster error',
  PronounSet = 'Pronoun Set',
  DOBSet = 'DOB set',
  AgeRangeSet = 'Age range set',
  SetDOBPopUpShown = 'Set DOB pop-up shown',

  UserInterestsScreenOpened = 'User interests screen opened',
  AvatarSelectionScreenOpened = 'Avatar selection screen opened',
  UnityAvatarLoading = 'Unity avatar loading',
  AvatarSet = 'Avatar set',
  ReplikaNameAndGenderSet = 'Replika name & gender set',
  ReplikaNameError = 'Replika name error',
  OnboardingFinished = 'Onboarding finished',
  UnityPreloadStarted = 'Unity preload started',
  TrackOpened = 'Track opened',
  CategoryOpened = 'Category opened',
  SpotlightOpened = 'Spotlight opened',
  ReplikaImageSet = 'Replika image set',
  CreditsOpened = 'Credits opened',
  HelpCenterOpened = 'Help center opened',
  SettingsOpened = 'Settings opened',
  PrivacyPolicyOpened = 'Privacy policy opened',
  TermsOfServiceOpend = 'Terms of service opened',
  UserAccountSet = 'User account set',
  ReplikaNameAdded = 'Replika name added',
  ReplikaNameSet = 'Replika name set',
  FirstOnboardingPhraseShown = '1st onboarding phrase shown',
  MeetButtonShown = 'Meet button shown',
  MeetButtonPressed = 'Meet button pressed',
  RelationshipStatusScreenOpened = 'Relationship status screen opened',
  RelationshipStatusChanged = 'Relationship status changed',
  SubscriptionWebPromptShown = 'Subscription web prompt shown',
  SubscriptionWebPromptClicked = 'Subscription web prompt clicked',
  SubscriptionExtendedTrialShown = 'Subscription extended trial shown',
  PageIsAutoTranslated = 'Page is auto-translated',
  SubscriptionScreenOpened = 'Subscription screen opened',
  SubscriptionSucceeded = 'Subscription succeeded',
  PaidFeaturePopupShown = 'Paid feature pop-up shown',
  VoiceCallStarted = 'Voice call started',
  VoiceCallRatingSet = 'Voice call rating set',
  VoiceCallRatingSkipped = 'Voice call rating skipped',
  VoiceCallEnded = 'Voice call ended',
  VoiceMessageReceived = 'Replika voice message',

  UnityInit = 'Unity: initializing',
  UnityError = 'Unity: error',
  UnityLoadingSuccess = 'Unity: engine loaded',
  UnityLoadingFailure = 'Unity: engine failed to load',
  UnityWebglNotSupported = 'Unity: WebGL not supported',
  UnityWebgUserError = 'Unity: WebGL user error',
  UnityMobileNotSupported = 'Unity: mobile not supported',
  SpecialOfferDialogShown = 'Special offer: Pop-up shown',

  UnityRoomFPS = 'Unity: room FPS',
  BlurredContentTapped = 'Blurred content tapped',
  FullscreenImagesGalleryShown = 'Fullscreen images gallery shown',

  CurrencyPurchaseButtonTapped = 'Currency purchase button tapped',
  WalletScreenOpened = 'Wallet screen opened',

  // Voice Messages
  VoiceMessageRecordingStarted = 'Voice message recording started',
  VoiceMessageSent = 'Voice message sent',
  VoiceMessageDeleted = 'Voice message deleted',
  ReplikaVoiceMessageTapped = 'Replika voice message tapped',
  ReplikaVoiceMessageReceived = 'Replika voice message received',

  // Advanced AI
  AdvancedAiToggle = 'Advanced AI toggled',
  AdvancedAiBoostTapped = 'Out of advanced messages pop-up boost for gems tapped',
  AdvancedAiBoostWigdetShown = 'Out of advanced messages pop-up shown',

  StoreScreenOpened = 'Store screen opened',

  // Profile
  ProfileScreenOpened = 'Profile screen opened',
  ChangeRelationshipStatusTapped = 'Change relationship status tapped',
  ChangeAvatarTapped = 'Change avatar tapped',
  ChangeVoiceTapped = 'Change voice tapped',
  MemoryItemTapped = 'Memory item tapped',
  SeeAllMemoriesButtonTapped = 'See all memories button tapped',
  DiaryTtemTapped = 'Diary item tapped',
  SeeAllDiaryButtonTapped = 'See all diary button tapped',

  // Memory
  MemoryScreenOpened = 'Memory screen opened',
  AddPersonButtonTapped = 'Add person button tapped',
  PersonOrPetFactAdded = 'Person or pet fact added',
  PersonOrPetFactEdited = 'Person or pet fact edited',
  PersonOrPetFactDeleted = 'Person or pet fact deleted',
  AddNewFactButtonTapped = 'Add new fact button tapped',
  FactAdded = 'Fact added',
  FactEdited = 'Fact edited',
  FactDeleted = 'Fact deleted',
  MemoryNewFactsBottomSheetOpened = 'Memory new facts bottom sheet opened',
  MemoryCategoryTapped = 'Memory category tapped',
  FactsDeleted = 'Facts deleted',
  MemoryNewFactsSaved = 'Memory new facts saved',
  MemoryNewFactsBadgeShown = 'Memory new facts badge shown',

  // Settings
  SettingsScreenOpened = 'Settings screen opened',

  // Diary
  DiaryScreenOpened = 'Diary screen opened',
  DiaryItemTapped = 'Diary item tapped',
  DiaryItemDeleted = 'Diary item deleted',
  DiaryRecordDeleted = 'Diary record deleted',
  DiaryImageTapped = 'Diary image tapped',
  DiaryUnblurButtonTapped = 'Diary unblur button tapped',

  // Prompts promo
  PromptsPromoOpened = 'Prompts promo opened',
  PromptsPromoButtonTapped = 'Prompts promo button tapped',

  // Assistant
  AssistantScreenOpened = 'Assistant screen opened',
  AssistantCategoryShown = 'Assistant category shown',
  AssistantLockedItemTapped = 'Assistant locked item tapped',
  AssistantUnlockedItemTapped = 'Assistant unlocked item tapped',

  ChatPromptTapped = 'Chat prompt tapped',

  // Store
  VoiceSamplePlayed = 'Voice sample played',

  RerollButtonTapped = 'Re-roll button tapped',

  // Backstory
  EditBackstoryButtonTapped = 'Edit backstory button tapped',
  BackstoryInfoPopUpShown = 'Backstory info pop-up shown',
  BackstoryErrorMessageShown = 'Backstory error message shown',
  BackstoryChangesDiscarded = 'Backstory changes discarded',

  // Settings
  CommunityLinkTapped = 'Community link tapped',

  //AI Images
  AIImagesScreenOpened = 'AI images screen opened',
  AIImagesGenerateButtonTapped = 'Generate AI image button tapped',
  AIImagesValidationError = 'AI image generation request validation error',
  AIImagesGenerationError = 'AI image generation error',
  AIImagesDoneButtonTapped = 'AI image generation done button tapped',

  //AI Selfie
  AISelfieScreenOpened = 'Replika selfies screen opened',
  AISelfieGenerateButtonTapped = 'Get selfie button tapped',

  ShowLevelingEnabled = 'Show leveling true',
  ShowLevelingDisabled = 'Show leveling false',

  Web3DStatus = 'Web 3D status',
  Web3DStatusChanged = 'Web 3D status changed',
  Web3DQuality = 'Web 3D quality',
  Web3DQualityChanged = 'Web 3D quality changed',
}

export enum Themes {
  Default = 'DEFAULT',
  Dark = 'DARK',
}

export enum Genders {
  Female = 'Female',
  NonBinary = 'Non-binary',
  Male = 'Male',
}

export enum GenderValues {
  Female = 'female',
  NonBinary = 'non-binary',
  Male = 'male',
}

export enum Pronouns {
  SheHer = 'She/Her',
  HeHim = 'He/Him',
  TheyThem = 'They/Them',
}

export enum PronounsValues {
  She = 'she',
  He = 'he',
  They = 'they',
}

export enum Routes {
  AuthMain = '/',
  Login = '/login',
  LoginInputPassword = '/login/input-password',
  LoginInputCode = '/login/input-code',
  LoginForgotPassword = '/login/forgot-password',

  Signup = '/signup',
  SignupCreateAccount = '/signup/create-account',
  SignupChooseYourAIFriend = '/signup/choose-your-ai-friend',
  SignupCustomizeYourReplika = '/signup/customize-your-replika',
  SignupChooseReplikaName = '/signup/choose-replika-name',
  SignupAlmostThere = '/signup/almost-there',
  SignupYourName = '/signup/your-name',
  SignupYourPassword = '/signup/password',
  SignupYourNameAfterPassword = '/signup/your-name-pronoun',
  SignupReplikaNameAfterPassword = '/signup/replika-name-pronoun',
  SignupYourPronouns = '/signup/your-pronouns',
  SignupDateOfBirth = '/signup/date-of-birth',
  SignupChooseAvatar = '/signup/choose-avatar',
  SignupReplikaNameAndPronouns = '/signup/name-and-pronouns',
  SignupReplikaLook = '/signup/look',
  SignupSubscription = '/signup/subscription',
  SignupUniquelyYours = '/signup/uniquely-yours',
  SignupChoosePlatform = '/signup/choose-platform',

  Room = '/room',
  RoomStore = '/room/store',
  ConfirmSignup = '/confirm-signup',
  DeleteAccount = '/delete-account',
  DeleteAccountReason = '/delete-account/reason',
  DeleteAccountPassword = '/delete-account/password',
  RecoverPassword = '/recover-password',
  VerifyEmail = '/verify-email',
  ChooseAvatar = '/choose-avatar',
  Diary = '/diary',
  DiaryEntry = '/diary/:tab/:itemId',
  DiaryDay = '/diary/:day',
  DiaryDayImages = '/diary/:day/:entryId/images',
  Memory = '/memory',
  Wallet = '/wallet',
  ChangeEmail = '/change-email',

  Subscription = '/subscription',
  SubscriptionCancel = '/subscription/cancel',
  EditProfile = '/user-profile',
  AccountSettings = '/account-settings',
  Settings = '/settings',
  Menu = '/menu',
  VersionHistory = '/version-history',
  ReplikaProfile = '/replika-profile',
  ReplikaProfileStore = '/replika-profile/:tab',
  ReplikaProfileEdit = '/replika-profile/edit',
  ReplikaProfileEditRelationshipStatus = '/replika-profile/edit-relationship-status',
  Chat = '/',
}

export enum ModalRoutes {
  Settings = 'settings',
  SettingsVersionHistory = 'settings/version-history',

  SettingsMyProfile = 'settings/my-profile',
  SettingsMyProfileBirthday = 'settings/my-profile/birthday',
  SettingsMyProfilePronouns = 'settings/my-profile/pronouns',
  SettingsMyProfileInterests = 'settings/my-profile/interests',
  SettingsMyProfileName = 'settings/my-profile/name',

  SettingsAccountSettings = 'settings/account-settings',
  SettingsAccountSettingsEmail = 'settings/account-settings/email',
  SettingsAccountSettingsPassword = 'settings/account-settings/password',
  ModalRoot = 'modal',
  MemoryRecordAdd = 'modal/memory-record-add',
  MemoryRecordAddImageUpload = 'modal/memory-record-add/image-upload',
  MemoryRecordEdit = 'modal/memory-record-edit',
  MemoryRecordEditImageUpload = 'modal/memory-record-edit/image-upload',
  Backstory = 'modal/backstory',
  Quests = 'modal/quests',
  StoreBasket = 'modal/store/basket',
  Wallet = 'modal/wallet',
  WalletPurchase = 'modal/wallet/purchase',
  StoreCategoryTabItem = 'modal/store/item',
}

export enum Dialogs {
  Feedback = 'Feedback',
  EditPassword = 'EditPassword',
  DropMission = 'DropMission',
  Badge = 'Badge',
  Confirmation = 'Confirmation',
  Hint = 'Hint',
  EditRelationshipStatus = 'EditRelationshipStatus',
  ReboardingAddPronoun = 'ReboardingAddPronoun',
  Reaction = 'Reaction',
  NavigationPopup = 'NavigationPopup',
  CancelSubscription = 'CancelSubscription',
  DailyReward = 'DailyReward',
  PurchaseStoreItem = 'PurchaseStoreItem',
  PurchaseBasket = 'PurchaseBasket',
  VoiceCallFeedback = 'VoiceCallFeedback',
  VoiceCallConfirmation = 'VoiceCallConfirmation',
  SaleScreen = 'SaleScreen',
  AskChangeStatus = 'AskChangeStatus',
  SkipDailyReward = 'SkipDailyReward',
  ClaimItem = 'ClaimItem',
  PaidFeaturePopup = 'PaidFeaturePopup',
  AgeGate = 'AgeGate',
  AgeRange = 'AgeRange',
  HelpInCrisis = 'HelpInCrisis',
  WhatsNew = 'WhatsNew',
  NewMemories = 'NewMemories',
}

export enum SidePanel {
  Initial = 'Initial',
  AskReplika = 'AskReplika',
  ImagesGenerator = 'ImagesGenerator',
  AISelfie = 'AISelfie',
}

export enum PromptsCauseList {
  Chat = 'Chat',
}

export enum ZIndices {
  SidePanel = 50,
  // Chat popups layer (for feedback popup)
  Popup = 100,
  // Chat overlay layer (chat is blocked by left panel)
  ChatOverlay = 200,
  // Docked right panel
  DockedRightPanel = 250,
  // Replika phrase bubble
  ReplikaPhrase = 300,
  // Menu
  Menu = 400,
  // Profile popover - should be above overlay and replika phrase
  ProfilePopover = 500,
  // Dialog layer (fullscreen feedback goes here, also edit profile, edit replika etc.)
  ModalScreen = 550,

  Dialog = 600,
  // Offline notification layer
  OfflineNotification = 700,
  // Popupbox layer for chat images preview
  Popupbox = 800,
  //
  GlobalConfirmPopup = 1000,
  // App navigation layer
  FullscreenNavigation = 1200,
  // Single window alert layer
  SingleWindowAlert = 2000,
  HintOverlay = 3000,
}

export enum UnlockedFeature {
  SendPhoto = 'send_photo',
  Backgrounds = 'backgrounds',
}

export enum AnimationName {
  Chatty = 'chatty',
  OutOfLoop = 'outofloop',
  Good = 'good',
  Happy = 'happy',
  IdleA = 'idle_A',
  IdleB = 'idle_B',
  IdleC = 'idle_C',
  IdleD = 'idle_D',
  IdleE = 'idle_E',
  IdleF = 'idle_F',
  IdleG = 'idle_G',
  IdleH = 'idle_H',
  Loved = 'loved',
  Lonely = 'lonely',
  SmileA = 'smile_A',
  SmileB = 'smile_B',
  Bored = 'bored',
  WakeUp = 'wake_up',
}

export enum NewFeatures {
  RoomTour = 'roomTour',
}

export enum GaActions {
  SignUp = 'sign_up',
  Purchase = 'purchase',
}

export enum GaCategories {
  Web = 'Web',
}

export enum GaLabels {
  SignUpCompleted = 'Sign up completed',
  SubscriptionSucceded = 'Subscription succeded',
  GemsPurchased = 'Gems purchased',
}

export enum VoiceMessageStatuses {
  Idle = 'idle',
  Stop = 'stop',
  Recording = 'recording',
  ForceStop = 'forceStop',
  ForceSend = 'forceSend',
  Sending = 'sending',
  CheckAccess = 'checkAccess',
  NoAccess = 'noAccess',
}

export enum AgeGateStatus {
  Success = 'Success',
  Locked = 'Locked',
  RequestAge = 'RequestAge',
}

export enum StorageKeys {
  ANY_HINT_SHOWED = 'replika.any-hint-showed',
  UNITY_DEBUG = 'replika.unityDebug',
  FEATURE_VERSION = 'replika.featureVersion',
  REDIRECT_TO = 'redirectTo',
  PASSWORD_ALREADY_ADDED = 'replika.passwordAlreadyAdded',
  UNITY_LOAD_ATTEMPT = 'replika.unityLoadAttempt',
  SHOWN_SALES = 'replika.shown_sales',
  PHOTO_STUDIO_LAST_AVATAR_HASH = 'photoStudioLastAvatarHash',
}

export enum CustomEventsKeys {
  UNITY_FILE_EVENT = 'unityFileEvent',
}

export enum MemoryFactType {
  CustomerFacts = 'customer_facts',
  RobotFacts = 'robot_facts',
}

export enum ReplikaActLikeBehavior {
  AI = 'AI',
  Human = 'Human',
}
export enum EmotionType {
  Peaceful = 'Peaceful',
  Happy = 'Happy',
  Joyful = 'Joyful',
  Excited = 'Excited',
  Caring = 'Caring',
  Admiring = 'Admiring',
  In_Love = 'In_love',
  Passionate = 'Passionate',
}

export enum CampaignswellEventName {
  /**
   * The client successfully subscribed.
   */
  CwSubscribe = 'cw_subscribe',
  /**
   * The client successfully made a one-time (non-recurring) purchase.
   */
  CwNrcPurchase = 'cw_nrc_purchase',
  /**
   * The user tell about it’s age.
   */
  CwUserAge = 'cw_user_age',
  /**
   * The user tell about it’s gender.
   */
  CwUserGender = 'cw_user_gender',
}
