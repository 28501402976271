import { replace } from 'connected-react-router';
import { useInputField } from 'form-atoms';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import { deleteAccount, sendLogOutRequest } from '../../actions/auth';
import AvatarProvider from '../../core/AvatarProvider';
import { AgeGateStatus, Routes } from '../../types/enums';
import AuthLayout from './AuthLayout';
import Login from './Login';
import Signup from './Signup';
import SignupChooseAvatar from './SignupChooseAvatar';
import SignupChoosePlatform from './SignupChoosePlatform';
import SignupReplikaLook from './SignupReplikaLook';
import SignupReplikaNameAndPronouns from './SignupReplikaNameAndPronouns';
import SignupSubscription from './SignupSubscription';
import SignupUniquelyYours from './SignupUniquelyYours';
import SignupYourAge from './SignupYourAge';
import SignupYourNameAndPronouns from './SignupYourNameAndPronouns';
import SignupYourPassword from './SignupYourPassword';
import Welcome from './Welcome';
import { passwordAtom } from './atoms';
import { usePrepareMainRouteAfterOnboarding } from './usePrepareMainRouteAfterOnboarding';

type Props = {
  className?: string;
  screen: 'welcome' | 'login' | 'signup';
};

type RouteConfig = {
  path: Routes;
  content: React.ReactNode;
  mode: 'left' | 'split' | 'right';
  backTo?: Routes;
  header?: React.ReactNode;
  subheader?: React.ReactNode;
  cameraSlot?:
    | 'desktop_onboarding_face'
    | 'desktop_onboarding'
    | 'desktop_subscription';
};

// Signup is divided into two parts: presignup and postsignup.
// Presignup is the part that happens before the user has an account.
// If presignup is completed, user account is created with some pre-filled fields for replika bot.
// After that, user credentials are stored and if user refreshes the page, the process is continued from this point.
// In postsignup, user chooses avatar, interests etc. end the whole signup is then completed.

const SIGNUP_ROUTE_CONFIG: RouteConfig[] = [
  {
    path: Routes.Signup,
    mode: 'right',
    backTo: Routes.AuthMain,
    header: 'Create an account',
    content: <Signup />,
  },
  {
    path: Routes.SignupYourName,
    mode: 'right',
    backTo: Routes.Signup,
    header: 'Your name & pronouns',
    content: <SignupYourNameAndPronouns />,
  },
  // presignup here
  {
    path: Routes.SignupYourPassword,
    mode: 'right',
    header: 'Create password',
    content: <SignupYourPassword />,
  },
  {
    path: Routes.SignupYourNameAfterPassword,
    mode: 'right',
    header: 'Your name & pronouns',
    content: <SignupYourNameAndPronouns />,
  },
  {
    path: Routes.SignupReplikaNameAfterPassword,
    mode: 'right',
    backTo: Routes.SignupYourNameAfterPassword,
    header: 'Name & Pronouns',
    content: <SignupReplikaNameAndPronouns />,
    cameraSlot: 'desktop_onboarding',
  },
  {
    path: Routes.SignupDateOfBirth,
    mode: 'right',
    backTo: Routes.SignupYourName,
    header: 'How old are you?',
    subheader:
      'We need this information to make your experience\nmore relevant & safe.',
    content: <SignupYourAge />,
  },
  {
    path: Routes.SignupChooseAvatar,
    mode: 'right',
    backTo: Routes.SignupDateOfBirth,
    header: 'Choose avatar',
    content: <SignupChooseAvatar />,
    cameraSlot: 'desktop_onboarding_face',
  },
  {
    path: Routes.SignupReplikaNameAndPronouns,
    mode: 'right',
    backTo: Routes.SignupChooseAvatar,
    header: 'Name & Pronouns',
    content: <SignupReplikaNameAndPronouns />,
    cameraSlot: 'desktop_onboarding',
  },
  {
    path: Routes.SignupUniquelyYours,
    mode: 'right',
    backTo: Routes.SignupReplikaNameAndPronouns,
    header: '',
    content: <SignupUniquelyYours />,
    cameraSlot: 'desktop_onboarding',
  },
  {
    path: Routes.SignupReplikaLook,
    mode: 'right',
    backTo: Routes.SignupUniquelyYours,
    header: 'Look',
    content: <SignupReplikaLook />,
    cameraSlot: 'desktop_onboarding_face',
  },
  {
    path: Routes.SignupChoosePlatform,
    mode: 'right',
    header: '',
    content: <SignupChoosePlatform />,
    cameraSlot: 'desktop_onboarding',
  },
  {
    path: Routes.SignupSubscription,
    mode: 'right',
    header: '',
    content: <SignupSubscription />,
    cameraSlot: 'desktop_subscription',
  },
];

function Auth({ className, screen }: Props) {
  let content: React.ReactNode;
  let mode: 'left' | 'split' | 'right' = 'left';
  let backTo: Routes | undefined;
  let header: React.ReactNode = null;
  let subheader: React.ReactNode = null;
  let backIcon: 'arrow-back' | 'close' = 'arrow-back';
  let backIconLabel: 'back' | 'close' = 'back';
  let backOnClick: React.MouseEventHandler<HTMLButtonElement> | undefined =
    undefined;
  let btnPosition: 'none' | 'left' | 'right' = 'left';
  let background: 'onboarding' | 'subscription' = 'onboarding';
  let hideLogo = false;

  const path = useLocation().pathname;

  const dispatch = useDispatch();

  const passwordField = useInputField<'password'>(passwordAtom);

  const userProfile = useSelector((state) => state.profile.persist.userProfile);
  const onboardingStatus = useSelector(
    (state) => state.signup.persist.onboardingStatus,
  );

  const ageGateLocked = userProfile?.birthday_status === AgeGateStatus.Locked;

  React.useEffect(() => {
    if (screen !== 'signup') return;

    const to = setTimeout(() => {
      setPreloadUnity(true);
    }, 1000);

    return () => clearTimeout(to);
  }, [screen]);

  React.useEffect(() => {
    if (ageGateLocked && path !== Routes.SignupDateOfBirth) {
      dispatch(replace(Routes.SignupDateOfBirth));
    }
  }, [ageGateLocked, dispatch, path]);

  React.useEffect(() => {
    if (
      path !== Routes.SignupSubscription &&
      onboardingStatus === 'subscription'
    ) {
      dispatch(replace(Routes.SignupSubscription));
    }
  }, [onboardingStatus, path]);

  const prepareMainRouteAfterOnboarding = usePrepareMainRouteAfterOnboarding();

  const welcomeScreen = screen === 'welcome';

  const [videoPaused, setVideoPaused] = React.useState(!welcomeScreen);

  React.useEffect(() => {
    setVideoPaused(true);
    const to = setTimeout(() => {
      setVideoPaused(false);
    }, 500);

    return () => clearTimeout(to);
  }, [welcomeScreen]);

  // This isn't good - too much stuff is decoupled from the route components.
  // The better way to handle this is probably to use contexts.

  switch (screen) {
    case 'welcome':
      content = <Welcome />;
      mode = 'left';
      break;

    case 'login':
      content = <Login />;
      mode = 'right';
      backTo = Routes.AuthMain;
      break;

    case 'signup':
      for (let config of SIGNUP_ROUTE_CONFIG) {
        if (matchPath(path, { path: config.path, exact: true })) {
          mode = config.mode;
          backTo = config.backTo;
          content = config.content;
          header = config.header;
          subheader = config.subheader;

          if (path === Routes.SignupDateOfBirth) {
            if (!ageGateLocked) {
              backOnClick = async (e) => {
                if (passwordField.props.value) {
                  await dispatch(
                    deleteAccount(
                      passwordField.props.value,
                      'web signup cancelled',
                      false,
                    ),
                  );
                } else {
                  await dispatch(sendLogOutRequest(false));
                }
              };
            } else {
              // block back button
              backTo = undefined;
              backOnClick = undefined;
            }
          }

          if (path === Routes.SignupSubscription) {
            btnPosition = 'right';
            backIcon = 'close';
            backIconLabel = 'close';
            backTo = Routes.Chat;
            hideLogo = true;
            backOnClick = (e) => {
              prepareMainRouteAfterOnboarding();
            };
          }
          break;
        }
      }

      break;
  }

  const inSignup = screen === 'signup';
  const [preloadUnity, setPreloadUnity] = React.useState(false);

  React.useEffect(() => {
    if (!inSignup) return;
    const to = setTimeout(() => {
      setPreloadUnity(true);
    }, 1000);

    return () => clearTimeout(to);
  }, [inSignup]);

  return (
    <AvatarProvider enabled={preloadUnity} force2d>
      <AuthLayout
        mode={mode}
        className={className}
        headerButtonTo={backTo}
        header={header}
        subheader={subheader}
        headerButtonOnClick={backOnClick}
        headerButtonIcon={backIcon}
        headerButtonPosition={btnPosition}
        headerButtonLabel={backIconLabel}
        videoPaused={videoPaused}
        background={background}
        hideLogo={hideLogo}
      >
        {content}
      </AuthLayout>
    </AvatarProvider>
  );
}

export default Auth;
